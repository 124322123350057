.orange {
  color: #f60;
}
.dark-gray {
  color: #666;
}
.gray {
  color: #999;
}
.green {
  color: #4b0;
}
.red {
  color: #f30;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
td,
th {
  font-weight: 400;
  vertical-align: middle;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
a img {
  border: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
body {
  min-width: 1000px;
  font-size: 14px;
  line-height: 1.5em;
  font-family: -apple-system, blinkmacsystemfont, Helvetica Neue, helvetica,
    arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei, stheiti, simsun,
    sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.c-gray,
.c-no-auth {
  color: #999 !important;
}
.retail-tooltip {
  background-color: #fff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  z-index: 2000;
}
.retail-tooltip .tooltip-arrow {
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  height: 6px;
  overflow: hidden;
  position: absolute;
  width: 6px;
  z-index: 1;
  -webkit-transform: translate(-50%, 50%) rotate(45deg);
  -moz-transform: translate(-50%, 50%) rotate(45deg);
  transform: translate(-50%, 50%) rotate(45deg);
}
.retail-tooltip .tooltip-inner {
  background: #fff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  white-space: nowrap;
  color: #333;
  padding: 10px 20px;
  position: relative;
  z-index: 2;
}
body #app-container {
  background: #f7f8fa;
  width: auto;
  margin-left: 224px;
  padding-bottom: 50px;
  min-width: 960px;
}
body #app-container .app__content,
body #app-container .page-content {
  width: auto;
}
body #app-container .app-inner,
body #app-container .app .app-inner {
  margin: 10px;
  padding: 15px;
  min-width: 750px;
  min-height: 500px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
body #app-container #app-third-sidebar {
  position: relative;
  height: 56px;
  min-width: 750px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 100px 0 5px;
  -webkit-transition: padding-right 0.5s;
  -moz-transition: padding-right 0.5s;
  transition: padding-right 0.5s;
}
body #app-container #app-third-sidebar.with-box-shadow {
  -webkit-box-shadow: 0 9px 10px -13px rgba(0, 0, 0, 0.6);
  box-shadow: 0 9px 10px -13px rgba(0, 0, 0, 0.6);
}
body #app-container #app-third-sidebar .ui-nav {
  border-bottom: 0;
  margin-bottom: 0;
}
body #app-container #app-third-sidebar .ui-nav ul {
  margin-bottom: 0;
}
body #app-container #app-third-sidebar .ui-nav li {
  margin-left: 0;
}
body #app-container #app-third-sidebar .ui-nav li a {
  height: 50px;
  padding: 0 20px;
  min-width: 0;
  border: 0;
  background: transparent;
  font-size: 14px;
  color: #666;
}
body #app-container #app-third-sidebar .ui-nav li span {
  display: inline-block;
}
body #app-container #app-third-sidebar .ui-nav li:hover span {
  color: #3283fa;
}
body #app-container #app-third-sidebar .ui-nav li.active span {
  color: #333;
  border-bottom: 2px solid #0099fc;
}
body #app-container #app-third-sidebar .ui-nav a {
  line-height: 48px;
}
body #app-container #app-third-sidebar .zent-breadcrumb {
  padding: 21px;
}
#app-footer {
  position: absolute;
  bottom: 0;
  left: 224px;
  right: 200px;
  height: 40px;
}
#app-footer a {
  width: 76px;
  height: 27px;
  display: block;
  margin: 0 auto;
  background: url(https://img01.yzcdn.cn/v2/image/www/footer/logov2.png)
    no-repeat;
}
@media only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  #app-footer a {
    background-image: url(https://img01.yzcdn.cn/v2/image/www/footer/logov2@2x.png);
    background-size: 76px 27px;
  }
}
.hide-help #app-footer {
  right: 0;
}
#app-container-top {
  padding: 10px 10px 0;
}
#app-container-top:empty {
  padding: 0;
  margin: 0;
  display: none;
}
body.without-second-sidebar #app-sidebar {
  width: 92px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
body.without-second-sidebar #app-container {
  margin-left: 92px;
}
body.without-second-sidebar #app-footer {
  left: 92px;
}
body.without-second-sidebar .app-design .app-actions {
  left: 100px;
}
body.without-third-sidebar #app-third-sidebar {
  display: none;
}
body.show-help #app-third-sidebar {
  padding-right: 210px;
}
@media only screen and (min-width: 1281px) {
  #app-container {
    margin-right: 200px;
  }
  #app-help-container {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
    border-left: 1px solid #e5e5e5;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #app-help-container .app-help-icon-close {
    display: none;
  }
  #app-third-sidebar {
    padding-right: 210px;
    margin-right: -200px;
  }
  body.show-help #app-help-container {
    z-index: 1;
  }
  body.show-help #app-container {
    margin-right: 200px;
  }
  body.show-help #app-third-sidebar {
    margin-right: -200px;
  }
  body.show-help .app-design .app-actions {
    right: 210px;
  }
  body.hide-help #app-help-container {
    -webkit-transform: translateX(200px);
    -moz-transform: translateX(200px);
    transform: translateX(200px);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  body.hide-help #app-container {
    margin-right: 0;
  }
  body.hide-help .app-design .app-actions {
    right: 10px;
  }
  body.hide-help #app-third-sidebar {
    padding-right: 100px;
    margin-right: 0;
  }
}
.mock-trigger {
  display: none;
}
.pull-right {
  float: right;
}
.pc-shared-icon__arrow-right {
  position: relative;
  width: 8px;
  height: 8px;
  display: inline-block;
  background: none;
}
.pc-shared-icon__arrow-right:after {
  content: "";
  position: absolute;
  top: 0;
  left: 33%;
  display: inline-block;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%) rotate(45deg);
  -moz-transform: translate(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: solid #878787;
  border-width: 1.25px 1.25px 0 0;
}
#app-container #app-header {
  position: relative;
}
#app-container #app-header #app-header-operations {
  display: none;
}
.app-sop-container {
  padding: 16px 16px 0;
}
/* body #shared-sidebar {
   width: 224px;
} */
body #shared-second-sidebar {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
}
body.without-second-sidebar #shared-sidebar {
  width: 92px;
}
body.without-second-sidebar #shared-second-sidebar {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
}
#shared-sidebar {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 92px;
  font-family: -apple-system, blinkmacsystemfont, Helvetica Neue, helvetica,
    arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei, stheiti, simsun,
    sans-serif;
  z-index: 11;
}
#shared-team-logo {
  position: fixed;
  top: 0;
  bottom: 0;
  height: 56px;
  width: 92px;
  word-break: break-word;
  background: #444;
  z-index: 1002;
}
#shared-team-logo .shared-team-logo-wrap {
  display: block;
  margin: 0 16px;
  height: 56px;
  overflow: hidden;
}
#shared-team-logo .shared-team-logo {
  margin: 12px auto 0;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-size: cover;
  background-position: 50% 50%;
  background-color: #fff;
  border: 1px solid #fff;
}
#shared-team-logo .shared-team-logo,
.shared-team-info {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.shared-team-info {
  background: #fff;
  display: none;
  position: fixed;
  left: 20px;
  top: 55px;
  color: #333;
  width: 300px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  font-family: -apple-system, blinkmacsystemfont, Helvetica Neue, helvetica,
    arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei, stheiti, simsun,
    sans-serif;
  z-index: 2010;
}
.shared-team-info:before {
  content: " ";
  width: 0;
  height: 0;
  position: absolute;
  left: 19px;
  top: -12px;
  border: 6px solid transparent;
  border-bottom-color: #fff;
}
.shared-team-info .shared-wrap {
  padding: 0 16px;
}
.shared-team-info .shared-wrap.shared-dashboard {
  -webkit-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
  background-color: #f8f8f8;
}
.shared-team-info .shared-wrap.shared-dashboard .shared-item {
  border-bottom: 0;
}
.shared-team-info .shared-wrap.shared-logout {
  -webkit-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
  background-color: #f8f8f8;
}
.shared-team-info .shared-wrap.shared-logout .shared-item,
.shared-team-info .shared-wrap.shared-user-info .shared-item {
  border-bottom: 0;
}
.shared-team-info .shared-wrap.shared-dashboard:hover,
.shared-team-info .shared-wrap.shared-logout:hover,
.shared-team-info .shared-wrap.shared-user-info:hover,
.shared-team-info .shared-wrap:hover {
  background-color: #155bd4;
}
.shared-team-info .shared-wrap.shared-dashboard:hover .shared-item,
.shared-team-info .shared-wrap.shared-logout:hover .shared-item,
.shared-team-info .shared-wrap.shared-user-info:hover .shared-item,
.shared-team-info .shared-wrap:hover .shared-item {
  color: #fff;
  border-color: #155bd4;
}
.shared-team-info .shared-wrap.shared-dashboard:hover .shared-shop-name,
.shared-team-info .shared-wrap.shared-dashboard:hover .shared-user-account,
.shared-team-info .shared-wrap.shared-dashboard:hover .zenticon,
.shared-team-info .shared-wrap.shared-logout:hover .shared-shop-name,
.shared-team-info .shared-wrap.shared-logout:hover .shared-user-account,
.shared-team-info .shared-wrap.shared-logout:hover .zenticon,
.shared-team-info .shared-wrap.shared-user-info:hover .shared-shop-name,
.shared-team-info .shared-wrap.shared-user-info:hover .shared-user-account,
.shared-team-info .shared-wrap.shared-user-info:hover .zenticon,
.shared-team-info .shared-wrap:hover .shared-shop-name,
.shared-team-info .shared-wrap:hover .shared-user-account,
.shared-team-info .shared-wrap:hover .zenticon {
  color: #fff;
}
.shared-team-info .shared-wrap.shared-dashboard:hover .dashboard-arrow-icon,
.shared-team-info .shared-wrap.shared-logout:hover .dashboard-arrow-icon,
.shared-team-info .shared-wrap.shared-user-info:hover .dashboard-arrow-icon,
.shared-team-info .shared-wrap:hover .dashboard-arrow-icon {
  background-image: url(https://img01.yzcdn.cn/v4/imgs/dashboard/arrow_lite.png);
}
.shared-team-info .shared-wrap.shared-dashboard:hover .shared-logout-logo,
.shared-team-info .shared-wrap.shared-logout:hover .shared-logout-logo,
.shared-team-info .shared-wrap.shared-user-info:hover .shared-logout-logo,
.shared-team-info .shared-wrap:hover .shared-logout-logo {
  background-image: url(https://img01.yzcdn.cn/v4/imgs/dashboard/logout_w.png);
}
.shared-team-info .shared-item {
  height: 56px;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  color: #111;
  border-bottom: 1px solid #eee;
  line-height: 20px;
}
.shared-team-info .shared-item-invite {
  color: #f44;
}
.shared-team-info .pop-target-wrap {
  width: 100%;
}
.shared-team-info .shared-shop-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}
.shared-team-info .shared-shop-text {
  width: 60px;
  margin-right: 20px;
  max-width: 150px;
}
.shared-team-info .shared-shop-img {
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.shared-team-info .shared-shop-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  margin: 0 10px;
  max-width: 150px;
  height: 40px;
  line-height: 20px;
}
.shared-team-info .shared-shop-name .shared-shop-name-content {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.shared-team-info .shared-user-text {
  margin-right: 70px;
}
.shared-team-info .shared-user-name {
  font-weight: 500;
  margin-bottom: 2px;
}
.shared-team-info .shared-shop-name,
.shared-team-info .shared-user-account {
  font-size: 12px;
  color: #999;
  font-weight: 400;
}
.shared-team-info .dashboard-arrow-icon {
  margin-right: 0;
  background-image: url(https://img01.yzcdn.cn/v4/imgs/dashboard/arrow_dark.png);
  background-size: cover;
  width: 16px;
  height: 16px;
}
.shared-team-info .shared-logout-logo {
  background-image: url(https://img01.yzcdn.cn/v4/imgs/dashboard/logout@2x.png);
  background-size: cover;
  width: 16px;
  height: 16px;
}
.zent-pop-v2.chain-store-select-pop
  .zent-pop-v2-inner
  .main-chain-select-container
  .chain-select-menu-list
  .chain-select-menu-item_prefix
  > .zenticon {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  font-size: 18px;
}
.zent-pop-v2.chain-store-select-pop
  .zent-pop-v2-inner
  .main-chain-select-container
  .chain-select-menu-list
  .chain-select-menu-item_prefix.right
  > .zenticon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
#shared-corner {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 42px;
  -moz-box-flex: 0;
  flex: 0 0 42px;
  background-color: #535353;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
}
#shared-corner,
#wsc-search-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}
#wsc-search-wrap {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  -moz-box-flex: 1;
  flex: 1 1 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
}
#wsc-search-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  width: 1px;
  height: 16px;
  background-color: #fff;
  opacity: 0.2;
  -webkit-transform: scaleX(0.5);
  -moz-transform: scaleX(0.5);
  transform: scaleX(0.5);
}
#wsc-search-wrap .bottom-icon {
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  background-size: cover;
}
#wsc-search-wrap .search-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-image: url(https://img01.yzcdn.cn/public_files/2019/01/21/e207dff7892b7a843e6b7b1645bc0887.png);
}
#notice-center {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  -moz-box-flex: 1;
  flex: 1 1 50%;
  padding: 10px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 18px;
  position: relative;
  background-image: url(https://img01.yzcdn.cn/public_files/2019/01/21/a5a9ab836ef7e449a16e9fdb2d2a9b8e.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
}
#shared-first-sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 92px;
  background-color: #444;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
}
#shared-first-sidebar-nav {
  margin-top: 56px;
  padding-right: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -moz-box-flex: 1;
  flex: 1 1 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
#shared-first-sidebar-nav li {
  width: 92px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
#shared-first-sidebar-nav li a {
  color: #c8c9cc;
  display: block;
  padding-left: 18px;
}
#shared-first-sidebar-nav li .sidebar-icon {
  background: url(https://b.yzcdn.cn/public_files/89383895a609ca9090dfa25e45731fe5.png)
    no-repeat;
  background-size: 72px 414px;
}
#shared-first-sidebar-nav li .sidebar-icon,
#shared-first-sidebar-nav li .sidebar-icon-svg {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-right: 4px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
#shared-first-sidebar-nav li .sidebar-icon-dashboard {
  background-position: 0 0;
}
#shared-first-sidebar-nav li .sidebar-icon-shop {
  background-position: 0 -18px;
}
#shared-first-sidebar-nav li .sidebar-icon-content {
  background-position: 0 -36px;
}
#shared-first-sidebar-nav li .sidebar-icon-goods {
  background-position: 0 -54px;
}
#shared-first-sidebar-nav li .sidebar-icon-order {
  background-position: 0 -72px;
}
#shared-first-sidebar-nav li .sidebar-icon-fans {
  background-position: 0 -90px;
}
#shared-first-sidebar-nav li .sidebar-icon-stat {
  background-position: 0 -108px;
}
#shared-first-sidebar-nav li .sidebar-icon-asset {
  background-position: 0 -126px;
}
#shared-first-sidebar-nav li .sidebar-icon-setting {
  background-position: 0 -144px;
}
#shared-first-sidebar-nav li .sidebar-icon-app {
  background-position: 0 -162px;
}
#shared-first-sidebar-nav li .sidebar-icon-youzan {
  background-position: 0 -198px;
}
#shared-first-sidebar-nav li .sidebar-icon-edu {
  background-position: 0 -306px;
}
#shared-first-sidebar-nav li .sidebar-icon-course {
  background-position: 0 -324px;
}
#shared-first-sidebar-nav li .sidebar-icon-crm {
  background-position: 0 -342px;
}
#shared-first-sidebar-nav li .sidebar-icon-financial {
  background-position: 0 -360px;
}
#shared-first-sidebar-nav li .sidebar-icon-xgj {
  background-position: 0 -378px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-dashboard {
  background-position: -36px 0;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-shop {
  background-position: -36px -18px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-content {
  background-position: -36px -36px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-goods {
  background-position: -36px -54px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-order {
  background-position: -36px -72px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-fans {
  background-position: -36px -90px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-stat {
  background-position: -36px -108px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-asset {
  background-position: -36px -126px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-setting {
  background-position: -36px -144px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-app {
  background-position: -36px -162px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-youzan {
  background-position: -36px -198px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-edu {
  background-position: -36px -306px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-course {
  background-position: -36px -324px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-crm {
  background-position: -36px -342px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-financial {
  background-position: -36px -360px;
}
#shared-first-sidebar-nav li .c-no-auth .sidebar-icon-xgj {
  background-position: -36px -378px;
}
#shared-first-sidebar-nav li.hover,
#shared-first-sidebar-nav li:hover {
  background: #666;
}
#authorityKey {
  background: #414e6c;
}
#shared-first-sidebar-nav li.hover a,
#shared-first-sidebar-nav li:hover a {
  color: #fff;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-dashboard,
#shared-first-sidebar-nav li:hover .sidebar-icon-dashboard {
  background-position: -54px 0;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-shop,
#shared-first-sidebar-nav li:hover .sidebar-icon-shop {
  background-position: -54px -18px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-content,
#shared-first-sidebar-nav li:hover .sidebar-icon-content {
  background-position: -54px -36px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-goods,
#shared-first-sidebar-nav li:hover .sidebar-icon-goods {
  background-position: -54px -54px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-order,
#shared-first-sidebar-nav li:hover .sidebar-icon-order {
  background-position: -54px -72px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-fans,
#shared-first-sidebar-nav li:hover .sidebar-icon-fans {
  background-position: -54px -90px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-stat,
#shared-first-sidebar-nav li:hover .sidebar-icon-stat {
  background-position: -54px -108px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-asset,
#shared-first-sidebar-nav li:hover .sidebar-icon-asset {
  background-position: -54px -126px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-setting,
#shared-first-sidebar-nav li:hover .sidebar-icon-setting {
  background-position: -54px -144px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-app,
#shared-first-sidebar-nav li:hover .sidebar-icon-app {
  background-position: -54px -162px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-youzan,
#shared-first-sidebar-nav li:hover .sidebar-icon-youzan {
  background-position: -54px -198px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-edu,
#shared-first-sidebar-nav li:hover .sidebar-icon-edu {
  background-position: -54px -306px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-course,
#shared-first-sidebar-nav li:hover .sidebar-icon-course {
  background-position: -54px -324px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-crm,
#shared-first-sidebar-nav li:hover .sidebar-icon-crm {
  background-position: -54px -342px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-financial,
#shared-first-sidebar-nav li:hover .sidebar-icon-financial {
  background-position: -54px -360px;
}
#shared-first-sidebar-nav li.hover .sidebar-icon-xgj,
#shared-first-sidebar-nav li:hover .sidebar-icon-xgj {
  background-position: -54px -378px;
}
#shared-first-sidebar-nav li.active {
  background: #fff;
}
#shared-first-sidebar-nav li.active a {
  color: #333;
}
#shared-first-sidebar-nav li.active .sidebar-icon-dashboard {
  background-position: -18px 0;
}
#shared-first-sidebar-nav li.active .sidebar-icon-shop {
  background-position: -18px -18px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-content {
  background-position: -18px -36px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-goods {
  background-position: -18px -54px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-order {
  background-position: -18px -72px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-fans {
  background-position: -18px -90px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-stat {
  background-position: -18px -108px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-asset {
  background-position: -18px -126px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-setting {
  background-position: -18px -144px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-app {
  background-position: -18px -162px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-youzan {
  background-position: -18px -198px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-edu {
  background-position: -18px -306px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-course {
  background-position: -18px -324px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-crm {
  background-position: -18px -342px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-financial {
  background-position: -18px -360px;
}
#shared-first-sidebar-nav li.active .sidebar-icon-xgj {
  background-position: -18px -378px;
}
@media only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  #shared-first-sidebar-nav li .sidebar-icon {
    background-image: url(https://b.yzcdn.cn/public_files/4d2a4ccea2685c126cd3587de772d34f.png);
    background-size: 72px 414px;
  }
}
.shared-second-sidebar {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  width: 132px;
  width: var(--second-nav-width, 132px);
  height: 100%;
  margin-left: 92px;
  background-color: #fff;
  border-right: 1px solid #ebedf0;
  z-index: 1;
  position: relative;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: -apple-system, blinkmacsystemfont, Helvetica Neue, helvetica,
    arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei, stheiti, simsun,
    sans-serif;
}
.shared-second-sidebar.ump-second-sidebar .shared-second-sidebar-nav {
  padding-left: 0;
}
.shared-second-sidebar.ump-second-sidebar .shared-second-sidebar-nav li {
  width: 132px;
  width: var(--second-nav-width, 132px);
  margin-right: 0;
}
.shared-second-sidebar.ump-second-sidebar .shared-second-sidebar-nav li a {
  padding: 0 16px 0 28px;
}
.shared-second-sidebar.ump-second-sidebar
  .shared-second-sidebar-nav
  li
  a
  .dashboard-arrow-icon {
  border-color: #999;
}
.shared-second-sidebar.ump-second-sidebar
  .shared-second-sidebar-nav
  li:not(.second-group-nav):hover {
  background: #f5f5f5;
}
.shared-second-sidebar.ump-second-sidebar
  .shared-second-sidebar-nav
  li:not(.second-group-nav):hover
  a {
  color: #323233;
  background: #f5f5f5;
}
.shared-second-sidebar .divider {
  margin: 19px 0 20px;
  height: 0;
  width: 108px;
}
.shared-second-sidebar .second-sidebar-title {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 65px;
  -moz-box-flex: 0;
  flex: 0 0 65px;
  width: 100%;
  text-align: center;
  height: 65px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
  font-size: 14px;
  color: #323233;
  line-height: 65px;
  font-weight: 500;
}
.shared-second-sidebar #shared-second-sidebar-nav,
.shared-second-sidebar .shared-second-sidebar-nav {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding-right: 20px;
  padding-left: 10px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.shared-second-sidebar #shared-second-sidebar-nav .has-more-indicator,
.shared-second-sidebar .shared-second-sidebar-nav .has-more-indicator {
  display: none;
}
.shared-second-sidebar #shared-second-sidebar-nav .has-more-indicator.visible,
.shared-second-sidebar .shared-second-sidebar-nav .has-more-indicator.visible {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  cursor: pointer;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  .has-more-indicator.visible:after,
.shared-second-sidebar
  .shared-second-sidebar-nav
  .has-more-indicator.visible:after {
  content: " ";
  position: relative;
  top: -2px;
  width: 8.485px;
  height: 8.485px;
  border: solid #878787;
  border-width: 1px 1px 0 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}
.shared-second-sidebar #shared-second-sidebar-nav .second-group-title,
.shared-second-sidebar #shared-second-sidebar-nav li,
.shared-second-sidebar .shared-second-sidebar-nav .second-group-title,
.shared-second-sidebar .shared-second-sidebar-nav li {
  position: relative;
  width: 108px;
  min-height: 30px;
  line-height: 30px;
  padding: 5px 0;
  color: #323233;
  cursor: pointer;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
}
.shared-second-sidebar #shared-second-sidebar-nav .second-group-title.active a,
.shared-second-sidebar #shared-second-sidebar-nav li.active a,
.shared-second-sidebar .shared-second-sidebar-nav .second-group-title.active a,
.shared-second-sidebar .shared-second-sidebar-nav li.active a {
  color: #323233;
  background-color: #ebedf0;
}
.shared-second-sidebar #shared-second-sidebar-nav .second-group-title a,
.shared-second-sidebar #shared-second-sidebar-nav li a,
.shared-second-sidebar .shared-second-sidebar-nav .second-group-title a,
.shared-second-sidebar .shared-second-sidebar-nav li a {
  position: relative;
  display: block;
  color: #323233;
  text-align: left;
  padding-left: 23px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 14px;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  .second-group-title
  .second-group-title,
.shared-second-sidebar #shared-second-sidebar-nav li .second-group-title,
.shared-second-sidebar
  .shared-second-sidebar-nav
  .second-group-title
  .second-group-title,
.shared-second-sidebar .shared-second-sidebar-nav li .second-group-title {
  margin: 0;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  li:not(.second-group-nav)
  .dashboard-arrow-icon,
.shared-second-sidebar
  .shared-second-sidebar-nav
  li:not(.second-group-nav)
  .dashboard-arrow-icon {
  position: absolute;
  right: 18px;
  top: 18px;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  li:not(.second-group-nav)
  .ump-menu-item
  .dashboard-arrow-icon,
.shared-second-sidebar
  .shared-second-sidebar-nav
  li:not(.second-group-nav)
  .ump-menu-item
  .dashboard-arrow-icon {
  top: 13px;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  li:not(.second-group-nav):hover
  a,
.shared-second-sidebar
  .shared-second-sidebar-nav
  li:not(.second-group-nav):hover
  a {
  color: #3773da;
}
.shared-second-sidebar #shared-second-sidebar-nav .second-group-nav,
.shared-second-sidebar .shared-second-sidebar-nav .second-group-nav {
  padding: 0;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  .second-group-nav
  .second-group-title,
.shared-second-sidebar
  .shared-second-sidebar-nav
  .second-group-nav
  .second-group-title {
  position: relative;
  cursor: pointer;
}
.shared-second-sidebar
  #shared-second-sidebar-nav
  .second-group-nav
  .dashboard-arrow-icon,
.shared-second-sidebar
  .shared-second-sidebar-nav
  .second-group-nav
  .dashboard-arrow-icon {
  position: absolute;
  top: 16px;
  left: -8px;
  margin-left: 12px;
  margin-right: 12px;
  -webkit-transform: scale(1.25) rotate(135deg);
  -moz-transform: scale(1.25) rotate(135deg);
  transform: scale(1.25) rotate(135deg);
}
.shared-second-sidebar #shared-second-sidebar-nav .second-group-nav a,
.shared-second-sidebar .shared-second-sidebar-nav .second-group-nav a {
  text-align: left;
  padding-left: 24px;
}
.shared-second-sidebar #shared-second-sidebar-nav .dashboard-arrow-icon,
.shared-second-sidebar .shared-second-sidebar-nav .dashboard-arrow-icon {
  display: inline-block;
  width: 4px;
  height: 4px;
  border: solid #878787;
  border-width: 1px 1px 0 0;
  -webkit-transform: scale(1.25) rotate(45deg);
  -moz-transform: scale(1.25) rotate(45deg);
  transform: scale(1.25) rotate(45deg);
  background: none;
}
.second-portal-wrap {
  position: fixed;
  top: 0;
  left: 92px;
  width: 132px;
  width: var(--second-nav-width, 132px);
  bottom: 0;
  z-index: 11;
}
.second-portal-wrap .shared-second-sidebar {
  margin-left: 132px;
  margin-left: var(--second-nav-width, 132px);
}
#shared-second-sidebar-toggle {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  top: 18px;
  width: 15px;
  height: 20px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  z-index: 3;
  cursor: pointer;
  right: -7px;
  display: none;
}
#shared-second-sidebar-toggle:after,
#shared-second-sidebar-toggle:before {
  content: "";
  position: absolute;
  top: 4px;
  display: inline-block;
  height: 12px;
  width: 1px;
  background: #dcdee0;
}
#shared-second-sidebar-toggle:before {
  right: 4px;
}
#shared-second-sidebar-toggle:after {
  left: 4px;
}
.super-store #shared-first-sidebar,
.super-store #shared-team-logo {
  background-color: #273543;
}
.super-store #shared-corner {
  background-color: #364555;
}
.super-store #shared-first-sidebar #shared-first-sidebar-nav li .sidebar-icon {
  background: url(https://img01.yzcdn.cn/upload_files/2020/04/26/FsE12EgG8ueWfY6wL9HgQgDc74Er.png)
    no-repeat;
  background-size: 36px 270px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-dashboard {
  background-position: 0 0;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-store {
  background-position: 0 -18px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-goods {
  background-position: 0 -36px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-order {
  background-position: 0 -54px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-fans {
  background-position: 0 -72px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-stat {
  background-position: 0 -90px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-assets {
  background-position: 0 -108px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-marketing {
  background-position: 0 -126px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-setting {
  background-position: 0 -144px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-cashier {
  background-position: 0 -162px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-shop {
  background-position: 0 -180px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-stock {
  background-position: 0 -198px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-purchase {
  background-position: 0 -216px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .retail-sidebar-icon-financial {
  background-position: 0 -254px;
}
.super-store #shared-first-sidebar #shared-first-sidebar-nav li.hover,
.super-store #shared-first-sidebar #shared-first-sidebar-nav li:hover {
  background: #434e6c;
}
.super-store #shared-first-sidebar #shared-first-sidebar-nav li.hover a,
.super-store #shared-first-sidebar #shared-first-sidebar-nav li:hover a {
  color: #fff;
}
.super-store #shared-first-sidebar #shared-first-sidebar-nav li.active {
  background: #38f;
}
.super-store #shared-first-sidebar #shared-first-sidebar-nav li.active a {
  color: #fff;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-dashboard {
  background-position: -19px 0;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-store {
  background-position: -19px -18px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-goods {
  background-position: -19px -36px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-order {
  background-position: -19px -54px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-fans {
  background-position: -19px -72px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-stat {
  background-position: -19px -90px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-assets {
  background-position: -19px -108px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-marketing {
  background-position: -19px -126px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-setting {
  background-position: -19px -144px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-cashier {
  background-position: -19px -162px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-shop {
  background-position: -19px -180px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-stock {
  background-position: -19px -198px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-purchase {
  background-position: -19px -216px;
}
.super-store
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .retail-sidebar-icon-financial {
  background-position: -19px -254px;
}
.super-store ~ div .shared-team-info .shared-wrap.shared-dashboard:hover,
.super-store ~ div .shared-team-info .shared-wrap.shared-logout:hover,
.super-store ~ div .shared-team-info .shared-wrap.shared-user-info:hover,
.super-store ~ div .shared-team-info .shared-wrap:hover {
  background-color: #38f;
}
.super-store
  ~ div
  .shared-team-info
  .shared-wrap.shared-dashboard:hover
  .shared-item,
.super-store
  ~ div
  .shared-team-info
  .shared-wrap.shared-logout:hover
  .shared-item,
.super-store
  ~ div
  .shared-team-info
  .shared-wrap.shared-user-info:hover
  .shared-item,
.super-store ~ div .shared-team-info .shared-wrap:hover .shared-item {
  border-color: #38f;
}
.fx-shop #shared-first-sidebar,
.fx-shop #shared-team-logo {
  background-color: #323233;
}
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li .sidebar-icon {
  background: url(https://img01.yzcdn.cn/public_files/2019/07/08/9678e23c3f32173d6571f17ee31223c8.png)
    no-repeat;
  background-size: 39px 208px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-board {
  background-position: 0 0;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-goods {
  background-position: 0 -24px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-customer {
  background-position: 0 -48px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-data {
  background-position: 0 -72px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-setting {
  background-position: 0 -96px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-shop {
  background-position: 0 -120px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-ump {
  background-position: 0 -144px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-assets {
  background-position: 0 -168px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li
  .fx-first-sidebar-order {
  background-position: 0 -192px;
}
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li.hover,
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li:hover {
  background: #434e6c;
}
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li.hover a,
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li:hover a {
  color: #fff;
}
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li.active {
  background: #155bd4;
}
.fx-shop #shared-first-sidebar #shared-first-sidebar-nav li.active a {
  color: #fff;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-board {
  background-position: -23px 0;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-goods {
  background-position: -23px -24px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-customer {
  background-position: -23px -48px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-data {
  background-position: -23px -72px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-setting {
  background-position: -23px -96px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-shop {
  background-position: -23px -120px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-ump {
  background-position: -23px -144px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-assets {
  background-position: -23px -168px;
}
.fx-shop
  #shared-first-sidebar
  #shared-first-sidebar-nav
  li.active
  .fx-first-sidebar-order {
  background-position: -23px -192px;
}
.fx-shop .noticie-center-g_dot {
  right: 30px;
}
.edu-shop #shared-first-sidebar-nav li.hover.blank,
.edu-shop #shared-first-sidebar-nav li:hover.blank {
  position: relative;
}
.edu-shop #shared-first-sidebar-nav li.hover.blank:after,
.edu-shop #shared-first-sidebar-nav li:hover.blank:after {
  content: "";
  background: url(https://b.yzcdn.cn/public_files/30f53ec92a2d45375e748695764dcfd7.svg)
    no-repeat;
  width: 7px;
  height: 7px;
  right: 7px;
  top: 17px;
  position: absolute;
}
#shared-team-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0 !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding-left: 6px;
}
.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0;
  padding-bottom: 0;
}
