.bg_box {
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100%;
}
.pd24 {
  box-sizing: border-box;
  padding: 24px;
}
.mb20 {
  margin-bottom: 20px;
}
.fz28 {
  display: inline-block;
  font-size: 28px;
  line-height: 28px;
  color: #323233;
  font-weight: 500;
  cursor: pointer;
}
.fz28:hover {
  color: #8558fa;
}
.ju-center {
  display: flex;
  justify-content: center;
}
.mb16 {
  margin-bottom: 16px;
}
.commonly {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -15px; */
}
.commonly-item {
  margin-left: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  width: calc(20% - 15px);
  height: 64px;
  justify-content: left;
}
.commonly-item:hover {
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
}
.commonly-img {
  width: 36px;
  height: 36px;
}
.w100 {
  width: 100%;
}
.pd16 {
  padding: 16px;
  box-sizing: border-box;
}
